import axios from 'axios';
import './App.css';
import React, { useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';

function UnitToColor( val ) {
  val = ( Math.atan(val) / ( Math.PI ) ) + (0.5);
  if (val <= 1/6) {
    return `rgb(255,${Math.round(val * 6 * 255)},0)`
  }
  val -= 1/6;
  if(val <= 1/6) {
    return `rgb(${255 - Math.round(val * 6 * 255)},255,0)`
  }
  val -= 1/6;
  if(val <= 1/6) {
    return `rgb(0,255,${Math.round(val * 6 * 255)})`
  }
  val -= 1/6;
  if(val <= 1/6) {
    return `rgb(0,${255 - Math.round(val * 6 * 255)},255)`
  }
  val -= 1/6;
  if(val <= 1/6) {
    return `rgb(${Math.round(val * 6 * 255)},0,255)`
  }
  val -= 1/6;
  if(val <= 1/6) {
    return `rgb(255,0,${255 - Math.round(val * 6 * 255)})`
  }
  return `rgb(255,0,0)`;
}

function PointTable( {arr, settip} ) {
  const [ newArr, setNewarr ] = useState([]);

  useEffect(() => {
    const axarr = [];
    while(arr.length) axarr.push(arr.splice(0,32));
    setNewarr(axarr);
  }, [arr])

  return(
    <>
      <table>
        <thead>
          <tr><td style={{fontWeight:"700"}}>Params:</td></tr>
        </thead>
        <tbody>
          {newArr.map((el, idx)=>(<tr key={idx}><td></td>{el.map((el2, idx2)=>(
          <td 
            data-tooltip-id="tip"
            onMouseOver={()=>settip(el2)}
            key={idx2}
            style={{ width:"10px", backgroundColor: UnitToColor(el2)}}>
          </td>
          ))}</tr>))}
        </tbody>
      </table>
    </>
  );
}

function ImageLine({settip}) {
  const [imgsrc, setImgsrc] = useState("");
  const [csv, setCsv] = useState("");

  function regen() {
    axios.get("https://rbt4168.csie.org/api/generate").then((res)=>{
      setImgsrc("data:image/png;base64,"+res.data.img);
      setCsv(atob(res.data.csv).split(","));
    }).catch((e)=>console.error(e));
  }

  useEffect(()=>{
    regen();
  }, []);

  return(
    <div style={{ border:"solid 3px grey", borderRadius:"15px" , padding: "10px" }}>
      <div style={{display:"flex", padding: "10px"}}>
        <button data-tooltip-id="tip" className='rbutton'
          onMouseOver={()=>settip("Re-Generate An Image")} onClick={regen}>Generate
        </button>
        <img src={imgsrc} data-tooltip-id="tip" style={{ width:"240px", height:"240px", marginRight: "10px" }}
          alt="GAN_img"
          onMouseOver={()=>settip("Image generated by (GAN) Generative Adversarial Network")}></img>
        <PointTable settip={settip} arr={csv}/>
      </div>
    </div>
  )
}

export default function App() {
  const [ tipContent, setTipContent ] = useState("");
  return (
    <div>
      <div style={{ padding: "3px 30px 30px 30px", gap: "10px" }}>
        <div style={{ border:"solid 3px grey", borderRadius:"15px" , padding: "10px" }}>
          <h2>Machine Learning Implementation -- StyleGAN2 + RealCUGAN Image Generator</h2>
          <h3>Reference : </h3>
          <a href="https://docs.google.com/presentation/d/1x8c38zgEbN2zN4EboWhquZ5b3LhVCN8ElhaJCO2vnzY">Homework Slide</a><br/>
          <a href="https://github.com/lucidrains/stylegan2-pytorch">StyleGAN2 GitHub</a><br/>
          <a href="https://github.com/bilibili/ailab/tree/main/Real-CUGAN">RealCUGAN GitHub</a><br/>
        </div>
        <ImageLine settip={setTipContent}/>
        <ImageLine settip={setTipContent}/>
        <ImageLine settip={setTipContent}/>
      </div>
      <Tooltip id="tip" place="top" content={tipContent}/>
    </div>
  )
}
